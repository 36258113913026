import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import SeoImage from '../assets/images/promo-hrt.jpg'

class HRCCaldwell extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query HRCCaldwellMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout>
                    <Seo title={`Hormone Replacement Therapy Caldwell, NJ | ${data.site.siteMetadata.title}`} description={`Testosterone replacement therapy in Caldwell provides a solution to testosterone decline and can help to improve quality of life by restoring the body to effective levels of testosterone.`} image={SeoImage} keywords={[``]} />
                    <Banner title='Hormone Replacement Therapy Caldwell, NJ' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={SeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <p class='introduction'>As men age, the functions of our reproductive organs decline, causing decreases in testosterone and hormonal levels, which are responsible for many functions within the body. Testosterone replacement therapy in Caldwell provides a solution to testosterone decline and can help to improve quality of life by restoring the body to effective levels of testosterone.</p>

                                    <p>Symptoms of low testosterone and hormone function include:</p>

                                    <ul>
                                        <li>fatigue</li>
                                        <li>weight gain or retention</li>
                                        <li>difficulty putting on muscle</li>
                                        <li>sexual difficulties and dysfunction</li>
                                        <li>decreased libido</li>
                                        <li>mood swings</li>
                                        <li>sleeping issues</li>
                                        <li>…and more</li>
                                    </ul>

                                    <p>Hormone replacement therapy in Caldwell can help to return testosterone levels to where they used to be and keep these symptoms from becoming overwhelming. Hormone receptors can be found all throughout the body, and imbalances can have severe quality of life issues.</p>

                                    <p>Age-related hormone and testosterone issues have become more of a problem today than ever before in our history. Testosterone decline is affecting more individuals, and at younger ages. At Optimal Health and Wellness in Caldwell, Dr. Sambataro makes an effort to stay informed on all of the latest research pertaining to hormone and testosterone therapy.</p>

                                    <p>If you are experiencing any of the symptoms above, and believe that the underlying issue may have something to do with a decline in testosterone, reach out to Dr. Sambataro today and see if you are a qualifying candidate for hormone therapy.</p>

                                    <h2>What is Hormone or Testosterone Replacement Therapy</h2>

                                    <p>There are a few types of testosterone replacement therapy that have been proven over time to be an effective way of regaining the positive effects of normal testosterone levels.</p>

                                    <p><strong>Topical Gel:</strong> Topical gel is one of the most common methods of testosterone therapy. Gel is generally applied to the arms, shoulders, or abdomen, and helps to replace the amount of testosterone available in the body.</p>

                                    <p><strong>Testosterone Injection:</strong> Testosterone injections are administered every 2 to 4 weeks, depending on the condition being treated, and helps to return testosterone levels to normal.</p>

                                    <p><strong>Testosterone Patches:</strong> Similar to the topical gel, testosterone patches are used to provide testosterone replacement for those with low levels of testosterone that are causing imbalances and issues. The patch is applied to the skin daily, entering the bloodstream and helping testosterone reach normal levels.</p>

                                    <p>Determining the right method of hormone replacement in Caldwell will depend on your consultation. Your doctor will determine which method will be most effective for you, as well as go over any possible side effects with each method.</p>

                                    <h2>Candidacy and Side Effects</h2>

                                    <p>It is extremely important to give a detailed medical history to your Caldwell doctor when discussing testosterone replacement therapy. As with any medical procedure, therapy, or surgery, there are risks that can certainly be exacerbated by medical conditions. Of course, even healthy individuals may have risks, but when performed by a reputable Caldwell doctor such as Dr. Sambataro, the risk of side effects should be greatly minimized.</p>

                                    <p>Potential side effects could include:</p>

                                    <ul>
                                        <li>acne</li>
                                        <li>fluid retention</li>
                                        <li>decreased testicle size</li>
                                        <li>breast enlargement</li>
                                        <li>decreased sperm count</li>
                                        <li>high blood pressure</li>
                                        <li>infertility</li>
                                        <li>increased red blood cells</li>
                                    </ul>

                                    <p>Most individuals that undergo testosterone therapy have extremely minimal side effects, and instead simply experience the benefits of regaining normal levels of testosterone. Again, it is very important to be completely transparent during your consultation with a Caldwell expert. This will ensure that none of your underlying health conditions will lead to severe side effects.</p>

                                    <h2>Consultation</h2>

                                    <p><a href={`tel:${data.site.siteMetadata.phone}`} rel='noopener noreferrer'>Call {data.site.siteMetadata.title}</a> in Caldwell today to see if hormone replacement therapy could be an effective solution for any testosterone-related issues. We can schedule a consultation and start the steps towards testosterone recovery and return you to a healthy quality of life.</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} hasCare={true} hasContact={true} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default HRCCaldwell